import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.7855685768786!2d-70.60994481998509!3d-33.42883441699914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf7aa9875823%3A0xe4baaa14f9cb4e40!2sCarlos%20Ant%C3%BAnez%202025%2C%20Oficina%20807%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1644963163855!5m2!1ses-419!2scl" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
        </>
    )
}

export default GoogleMap;  